<template>
  <v-container fluid>
    <v-row>
      <v-form ref="form" @submit.prevent="validateBeforeSubmit">
        <v-col cols="12">
          <v-radio-group
            v-model="vm.allegationSubjects"
            label="Is there a subject or subjects of allegation for this incident?"
            v-validate="'required'"
            data-vv-as="Is there a subject or subjects of allegation for this incident"
            name="allegationSubjects"
            :error-messages="errors.collect('allegationSubjects')"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </v-col>

        <template v-if="vm.allegationSubjects == true">
          <v-btn @click="addSubject">Add subject</v-btn>
          <v-col cols="12">
            <v-data-table
              id="list-table"
              ref="datatable"
              :headers="headers"
              :items="subjects"
              item-key="index"
              class="mr-2"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td>{{ props.item.incidentOverview.incidentTitle}}</td>
                  <td>{{ props.item.incidentCategory.incidentPrimaryCategory }}</td>
                  <td>{{ props.item.incidentOverview.incidentNotificationType }}</td>
                  <td>{{ props.item.providerDetails.reportCompletedBy }}</td>

                  <td>
                    <v-icon
                      slot="activator"
                      small
                      class="mr-2"
                      @click="editItem(props.item)"
                    >mdi-pencil</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </template>

        <v-btn color="primary" type="submit">Continue</v-btn>
      </v-form>
    </v-row>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
//import * as incidentService from "../../../services/incidentsService";
Vue.use(VeeValidate);

export default {
  name: "subjects-of-allegation",

  data: () => ({
    vm: [],
    gender: ["Male", "Female", "Indeterminate", "Intersex", "Unspecified"],
    headers: [
      { text: "First Name", value: "subjectFirstName" },
      { text: "Last Name", value: "subjectLastName" },
      { text: "Type", value: "subjectType" }
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentIncident() {
      return this.$store.getters.currentIncident;
    }
  },
  methods: {
    init() {
      this.vm =
        this.currentIncident == undefined ||
        this.currentIncident.subjectsOfAllegation == undefined
          ? {}
          : this.currentIncident.subjectsOfAllegation;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentIncident.subjectsOfAllegation = this.vm;
          this.$store.dispatch("setCurrentIncident", this.currentIncident);
          this.next();
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },

    next() {
      this.$emit("next-step");
    }
  },
  editItem(item) {
    this.$store.commit("setCurrentIncidentSubject", item);
    this.$router.push("/subjects-of-allegation-add");
  },
  addSubject() {
    this.$store.commit("setCurrentIncident", null);
    this.$router.push("/subjects-of-allegation-add");
  },
  deleteItem(item) {
    const index = this.currentCase.subjectsOfAllegation.indexOf(item);
    confirm("Are you sure you want to delete this item?") &&
      this.currentCase.subjectsOfAllegation.splice(index, 1);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

